import '../css/main.css';

import Vue from 'vue';
import VueScrollTo from 'vue-scrollto';
import Draggable from 'vuedraggable';
import Modal from './components/Modal.vue';
import ConfirmDialog from './components/ConfirmDialog.vue';
import FaIcon from './components/FaIcon.vue';
import ActionButton from './components/ActionButton.vue';

jQuery(function($) {
    $.ajaxSetup({
        beforeSend: function (xhr) {
            xhr.setRequestHeader('X-WP-Nonce', window.vmt.apiNonce);
        }
    });
    $('#mepr-account-logout').magnificPopup({
        type: 'inline',
        closeBtnInside: false
    });
});

// Vue instances
(function() {
    const apps = document.querySelectorAll('.vmt-app');
    if (!apps.length) {
        return;
    }
    Vue.directive('visible', function(el, binding) {
        el.style.visibility = !!binding.value ? 'visible' : 'hidden';
    });
    Vue.mixin({
        props: {
            json: {
                default: () => {}
            }
        },
        beforeCreate() {
            if (!this.$slots.default) {
                return;
            }
            const jsonVNode = this.$slots.default.find(
                vnode => vnode.tag === 'script' && vnode.data.attrs.type === 'application/json'
            );
            if (!jsonVNode) {
                return;
            }
            const json = jsonVNode.children[0].text;
            this.$options.propsData.json = JSON.parse(json);
        }
    });
    Vue.use(VueScrollTo);

    Vue.component('draggable', Draggable);
    Vue.component('modal', Modal);
    Vue.component('confirm-dialog', ConfirmDialog);
    Vue.component('fa-icon', FaIcon);
    Vue.component('action-button', ActionButton);

    window.Vue = Vue;

    jQuery(function() {
        apps.forEach((el) => new Vue({el}));
    });

})();
