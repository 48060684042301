<template>
    <i class="fa" :class="classes" :aria-hidden="!hasSRText"><span class="sr-only" v-if="hasSRText"><slot></slot></span></i>
</template>

<script>
    export default {
        name: "FaIcon",
        props: {
            name: String,
            large: Boolean,
            medium: Boolean
        },
        computed: {
            classes() {
                const name = this.name.replace('fa-', '');
                let classes = {'fa-2x': this.large, 'fa-1_5x': this.medium};
                classes['fa-' + name] = true;
                return classes;
            },
            hasSRText() {
                return !!this.$slots.default;
            }
        },

    }
</script>
