import { render, staticRenderFns } from "./FaIcon.vue?vue&type=template&id=d7513996&"
import script from "./FaIcon.vue?vue&type=script&lang=js&"
export * from "./FaIcon.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/opt/atlassian/pipelines/agent/build/wp-content/plugins/vmt-custom/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('d7513996')) {
      api.createRecord('d7513996', component.options)
    } else {
      api.reload('d7513996', component.options)
    }
    module.hot.accept("./FaIcon.vue?vue&type=template&id=d7513996&", function () {
      api.rerender('d7513996', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "assets/js/components/FaIcon.vue"
export default component.exports