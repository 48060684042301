var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "i",
    {
      staticClass: "fa",
      class: _vm.classes,
      attrs: { "aria-hidden": !_vm.hasSRText }
    },
    [
      _vm.hasSRText
        ? _c("span", { staticClass: "sr-only" }, [_vm._t("default")], 2)
        : _vm._e()
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }