<template>
  <button ref="button" class="vmt-action-button" :class="{active: active}" :style="styleVars">
    <slot></slot>
  </button>
</template>

<script>
export default {
  name: "ActionButton",
  props: {
    color: String,
    icon: String,
    iconSize: {
      type: String,
      default: '16px'
    },
    active: Boolean
  },
  computed: {
    styleVars() {
      return {
        '--action-button-color': this.color,
        '--action-button-icon': `'${this.icon}'`,
        '--action-button-icon-size': this.iconSize
      }
    }
  },
  watch: {
    active() {
      this.$refs.button.blur();
    }
  }
}
</script>

<style scoped lang="scss">
.vmt-action-button {
  background: #ffffff;
  color: var(--action-button-color);
  border: 1px solid var(--action-button-color);
  text-transform: unset;
  font-size: 16px;
  line-height: 1;
  border-radius: 10px;
  padding: 8px 35px;
  text-align: center;
  position: relative;

  &:hover,
  &:focus,
  &.active {
    background: var(--action-button-color);
    color: #ffffff;
  }

  &:before,
  &:after {
    content: var(--action-button-icon);
    position: absolute;
    z-index: 1;
    font-family: fontawesome;
    font-size: var(--action-button-icon-size);
    font-style: normal;
    line-height: 13px;
  }

  &:before {
    left: 12px;
  }
  &:after {
    right: 12px;
  }
}
</style>