<template>
    <transition name="vmt-fade">
        <span class="confirm-dialog" v-if="value">
            <span><slot></slot></span>
            <span class="confirm-actions">
                <button @click.stop="$emit('confirm')">Yes</button>
                <button @click.stop="$emit('input', false)" class="button-secondary">No</button>
            </span>
        </span>
    </transition>
</template>
<script>
    export default {
        name: 'confirm-dialog',
        props: {
            value: Boolean,
        },

    }
</script>
<style scoped lang="scss">
    @import '../../css/_variables.scss';
    .confirm-dialog {
        position: absolute;
        z-index: 2;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        display: flex;
        flex-flow: column;
        align-items: center;
        justify-content: center;
        padding: 1rem 2rem;
        background-color: $gray-light-o90;
        text-align: center;
        border-radius: inherit;

        span {
            display: block;
        }

        .confirm-actions {
            text-align: center;
            margin-top: 1rem;

            button {
                margin: 0 0.5rem;
            }
        }
    }
</style>
