var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("transition", { attrs: { name: "vmt-fade" } }, [
    _vm.open
      ? _c("div", { staticClass: "vmt-modal vmt-component" }, [
          _c("div", { staticClass: "wrapper" }, [
            _c(
              "div",
              { staticClass: "content" },
              [
                _vm._t("title", function() {
                  return [_c("h3", [_vm._v(_vm._s(_vm.title))])]
                }),
                _vm._v(" "),
                _c("div", [_vm._t("default")], 2),
                _vm._v(" "),
                _c(
                  "confirm-dialog",
                  {
                    on: { confirm: _vm.close },
                    model: {
                      value: _vm.showConfirmation,
                      callback: function($$v) {
                        _vm.showConfirmation = $$v
                      },
                      expression: "showConfirmation"
                    }
                  },
                  [_vm._v("Are you sure you want to close without saving?")]
                )
              ],
              2
            ),
            _vm._v(" "),
            _c(
              "button",
              {
                staticClass: "button-reset button-close",
                attrs: { type: "button", "aria-label": "Close modal" },
                on: { click: _vm.closeWithConfirm }
              },
              [_vm._v("✕")]
            )
          ])
        ])
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }