<template>
    <transition name="vmt-fade">
    <div class="vmt-modal vmt-component" v-if="open">
        <div class="wrapper">
            <div class="content">
                <slot name="title"><h3>{{ title }}</h3></slot>
                <div>
                    <slot></slot>
                </div>
                <confirm-dialog v-model="showConfirmation" @confirm="close">Are you sure you want to close without saving?</confirm-dialog>
            </div>
            <button
                class="button-reset button-close" type="button" aria-label="Close modal"
                @click="closeWithConfirm"
            >&#10005;</button>
        </div>
    </div>
    </transition>
</template>

<script>
    export default {
        name: "Modal",
        props: {
            open: Boolean,
            confirmClose: Boolean,
            title: String
        },
        data() {
            return {
                showConfirmation: false
            }
        },
        watch: {
            open(newValue) {
                if (newValue) {
                    window.addEventListener('keydown', this.keydown);
                } else {
                    window.removeEventListener('keydown', this.keydown);
                }
                document.body.classList.toggle('vmt-lock-scroll', !!newValue);
            }
        },
        methods: {
            keydown(e) {
                const code = e.which || e.keyCode;
                if (code === 27 /* ESC */) {
                    this.closeWithConfirm();
                }
            },
            closeWithConfirm() {
                if (this.confirmClose) {
                    this.showConfirmation = true;
                } else {
                    this.close();
                }
            },
            close() {
                this.showConfirmation = false;
                this.$emit('update:open', false)
            }
        }
    }
</script>

<style scoped lang="scss">
    @import '../../css/_variables.scss';
    .vmt-modal {
        position: fixed;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background: $gray-medium-o75;
        overflow: hidden;
        z-index: 9999;
        backface-visibility: hidden;
        overflow-y: auto;
        -webkit-overflow-scrolling: touch;
        -ms-overflow-style: none;
        display: flex;

        .wrapper {
            position: relative;
            display: flex;
            flex-flow: column wrap;
            width: 100%;
            align-items: center;
            pointer-events: auto;
            overflow-y: auto;
            @media (min-width: 768px) {
                padding: 2rem;
                max-width: 50rem;
                height: auto;
                margin: auto;
            }
            &::-webkit-scrollbar {
                display: none;
            }
        }
        .content {
            position: relative;
            width: 100%;
            max-width: 50rem;
            padding: 2rem;
            margin: auto;
            background-image: linear-gradient(to bottom, $coral, $orange);

            @media (min-width: 768px) {
                border-radius: 1rem;
                box-shadow: 4px 4px 8px 0 $gray-medium;
            }
            >>> h3 {
                color: inherit;
            }
        }
        .button-close {
            position: absolute;
            z-index: 1;
            top: 1rem;
            right: 1rem;
            height: 2rem;
            width: 2rem;
            line-height: 2rem;
            font-size: 2rem;
            color: $ink;
            text-align: center;
            overflow: hidden;
            @media (min-width: 768px) {
                top: 3rem;
                right: 3rem;
            }
            &:hover {
                color: #ffffff;
            }
        }
        .confirm-dialog {
            justify-content: flex-start;
            padding-top: 3rem;
            background-color: $coral-o90;
        }
    }
</style>
