var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("transition", { attrs: { name: "vmt-fade" } }, [
    _vm.value
      ? _c("span", { staticClass: "confirm-dialog" }, [
          _c("span", [_vm._t("default")], 2),
          _vm._v(" "),
          _c("span", { staticClass: "confirm-actions" }, [
            _c(
              "button",
              {
                on: {
                  click: function($event) {
                    $event.stopPropagation()
                    return _vm.$emit("confirm")
                  }
                }
              },
              [_vm._v("Yes")]
            ),
            _vm._v(" "),
            _c(
              "button",
              {
                staticClass: "button-secondary",
                on: {
                  click: function($event) {
                    $event.stopPropagation()
                    return _vm.$emit("input", false)
                  }
                }
              },
              [_vm._v("No")]
            )
          ])
        ])
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }